<template>
  <div class="container">
    <div class="information">
      <!-- 输入任意文本 -->
      <van-field v-model="name" label="中文名字" placeholder="请输入真实姓名" />
      <!-- 选择证件 -->
      <van-cell is-link @click="showPopup"
        ><span class="sessionTime_text">证件类型</span>
        <span class="sessionTime_text2">{{ certificateType }}</span></van-cell
      >
      <!-- 允许输入正整数，调起纯数字键盘 -->
      <van-field
        v-model="cardNum"
        label="证件号"
        placeholder="请正确输入证件号码"
      />
      <!-- 输入手机号，调起手机号键盘 -->
      <van-field
        v-model="phone"
        type="tel"
        placeholder="请正确输入手机号"
        label="手机号"
      />
    </div>

    <!-- 预约服务协议 -->
    <div class="agree">
      <van-checkbox v-model="checked">
        <span class="readAgree">阅读并同意</span>
      </van-checkbox>
      <span class="xieyi" @click="xieyi()">预约服务协议</span>
    </div>

    <!-- 提交按钮 -->

    <div class="yuyueButton" @click="submit()">保存</div>

    <!-- 身份证-->
    <van-popup v-model="show" position="bottom" :style="{ height: '40%' }" >
      <van-picker
      default-index='0'
        title="标题"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
        @change="onChange"
      />
    </van-popup>

    <van-dialog
      v-model="read"
      title="杭州市博物文化传播有限公司服务协议"
      confirm-button-text="知道了"
    >
      <div style="height: 70vh; overflow: scroll">
        <pre class="protocol">


                    尊敬的用户： 您好！
                        感谢您选择杭州博物文化传播有限公司产品与服务（简称“博物文化”）。在注册博物文化账号及使用博物文化服务前，请用户审慎阅读、充分理解以下所述条款的内容，特别是限制、排除或者免除责任的条款。
                        本服务协议的所有条款即构成贵方与杭州博物文化传播有限公司（简称“博物文化”）之间关于贵方使用博物文化服务所达成的协议（简称“本协议”），用于规范双方业务行为，明确双方的权利和义务，保障双方合法权益。用户在使用博物文化服务之前，请仔细阅读本协议，如用户不同意本服务协议的任何条款，请不要使用博物文化产品与服务。贵方勾选或点击“我已阅读并同意《博物文化服务协议》”按钮或以第三方平台账号登录或实际使用博物文化服务，即视为贵方已接受本协议的全部条款，本协议即开始生效。
                        1. 定义 
                            1.1
                            文旅绿码观众分时预约系统（以下简称“文旅绿码”），是指博物文化及其关联公司经营的一款观众分时预约系统（包括但不限于博物文化经营或合作的网站、APP、小程序、H5页面、博物文化电脑端及移动客户端软件等），为用户提供数据存储、管理、分享的信息存储空间服务和博物馆、景区等场所的预约、票务等服务。
                            1.2 “用户”，是指任何使用文旅绿码及/或通过文旅绿码预约博物馆、景区参观游览的使用者，包括但不限于法人、其他组织或自然人。 
                        2. 协议生效及变更
                            2.1所有拟使用、使用博物文化全部或部分服务的用户，应当首先同意本协议的所有条款，特别是免除或者限制责任的条款，限制、免责条款可能以加粗形式提示贵方注意。如果贵方不接受本协议，请不要使用博物文化全部或部分服务。
                            2.2即使用户在注册账号过程中没有选择“已查看并同意《博物文化服务协议》”，贵方实际访问、浏览及使用博物文化提供的各项服务、业务的行为，即视为对本协议的完全接受，并同意受本协议的约束。 
                            2.3 本协议是用户与博物文化之间关于用户使用博物文化服务所订立的协议，在用户开通或使用某项特定服务时，可能会涉及某项服务的单独协议，所有单独协议为本协议不可分割的组成部分，与本协议具有同等法律效力。 
                            2.4 博物文化保留依自主判断在将来的任何时间变更、修改、增加或删除本协议的权利，一旦发生服务条款的变动，博物文化将公示修改的内容，所有修改的内容均构成本协议的一部分。当用户使用博物文化的任何服务时，应接受博物文化随时提供的与该服务相关的规则或说明，并且此规则或说明均构成本服务条款的一部分。如果贵方不同意服务条款的修改，请立即主动取消、停止已经获得的服务；如果贵方继续使用博物文化服务，则视为贵方已经接受服务条款的修改。
                        3. 信息存储空间服务郑重声明 
                            3.1 博物文化郑重提请您注意，博物文化目前仅向用户提供分时预约技术服务及信息网络存储空间服务，博物文化本身不保证内容的真实性、合法性、正确性、完整性。 
                            3.2 博物文化非常重视和尊重版权及其他知识产权的保护，博物文化已在本协议及特定协议中约定用户使用博物文化服务不得侵害他人之合法权益，并尽商业上合理的注意义务设置相应的技术措施及规范防止用户通过博物文化服务侵权他人之合法权益。
                            如用户发现博物文化中之任何内容或用户使用博物文化服务产生的内容涉嫌侵害其合法权益的，应按照《信息网络传播权保护条例》有关规定及时发送邮件到bowuwh@163.com并提供权属证明、初步侵权证据等所需材料,具体操作请查阅《版权投诉指引》。博物文化将在收到权利通知后的合理时间内按照相关规定进行处理。
                        4. 用户使用规则 
                        4.1 用户资格
                        您确认，在您开始使用/注册程序使用我方平台服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力，则应获得监护人的知情同意，您及您的监护人应依照法律规定承担因此而导致的相应的责任。特别地，如果您是未成年人，请在您的监护人的同意和指导下访问和/或使用我方平台网站。
                        同时，用户本人及其代为预约人员的健康状况均符合其预约参观的博物馆、景点等场所的相关要求，否则用户将依照法律规定承担因此而导致的全部责任。 
                        4.2 非商业使用
                        用户承诺，未经博物文化书面同意，用户不得利用文旅绿码进行销售或其他商业使用等用途。 
                        4.3 合法使用
                        （1）用户使用博物文化服务时必须遵守中华人民共和国法律、法规和政策，不得利用博物文化服务制作、复制、发布、传播如下内容： 
                            （a）反对宪法所确定的基本原则的；
                            （b）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的； 
                            （c）损害国家荣誉和利益的； 
                            （d）破坏国家宗教政策，宣扬邪教和封建迷信的； 
                            （e）散布谣言，扰乱社会秩序，破坏社会稳定的；
                            （f）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的； 
                            （g）侮辱或者诽谤他人，侵害他人合法权益的； 
                            （h）含有法律、法规和政策禁止的其他内容的信息。
                        （2）博物文化一直致力于为用户提供文明、健康、规范有序的网络环境，用户不得利用博物文化发布以下内容或从事以下行为： 
                             (a) 诱导未成年人违法犯罪和渲染暴力、色情、赌博、恐怖活动的；
                            （b）色情网站、成人用品网站等含有任何性或性暗示的内容； 
                            （c）国家明令禁止或未取得国家许可销售的产品，或假冒、伪劣产品或未经授权销售的产品；
                            （d）组织、宣传网络兼职，或虚假人气、贩卖虚拟货币，或骚扰、垃圾广告或信息的；
                            （e）涉及他人隐私、个人信息或资料的； 
                            （f）侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；
                            （g）其他侵犯其他用户或第三方合法权益的。 
                        （3）用户不得利用文旅绿码实施以下行为： 
                            （a) 未经博物文化书面许可，对文旅绿码进行反向工程、反向汇编、反向编译或以其他方式获取文旅绿码软件源代码；
                            （b）未经博物文化书面许可，对于文旅绿码软件相关信息，擅自实施包括但不限于下列行为：使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版，建立镜像站点、借助博物文化软件发展与之有关的衍生产品、作品、服务、插件、外挂、兼容、互联等删除博物文化软件及其他副本上所有关于版权的信息、内容；
                            （c）使用文旅绿码服务过程中，对文旅绿码交互数据进行复制、更改、修改的； 
                            （d）利用文旅绿码服务推广其他第三方运营平台或互相推广； 
                            （e）提交、发布虚假信息，或冒充、利用他人名义；
                            （f）诱导、欺骗、强迫其他用户关注、点击链接页面或分享信息； 
                            （g）虚构、编造、散布虚假或片面事实，欺骗、误导他人的； 
                            （h）注册信息、申请认证资料与实际情况不一致的；
                            （i）制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播的； 
                            （j）其他利用文旅绿码服务从事违反、犯罪活动，或任何干扰文旅绿码服务正常运营，或违反文旅绿码平台规则和要求的；
                            若博物文化发现用户有上述行为的，所有责任由用户单方承担。同时博物文化有权根据违规情节，有权采取包括但不限于以下处罚措施：警告、限制或禁止使用全部或部分服务功能、暂时或永久冻结封禁账号、回收注销账号、扣罚保证金等。
                        4.4 用户账号使用规范 
                            （1）用户使用文旅绿码服务时，需注册文旅绿码账号。用户在创建账号时，必须提供用户名和密码，以及完成注册所要求提交的个人资料信息、工作单位信息。
                            （2）用户应确保注册资料的真实性和准确性，并保证仅使用其个人信息或依法获得授权的信息注册文旅绿码账号。如注册资料有任何变动，须及时更新，以便博物文化确认贵方的身份，或为贵方提供密码找回等服务。
                            （3）用户根据要求完成注册申请后，可获得特定文旅绿码账号的使用权，但账号的所有权始终归博物文化所有。
                            （4）博物文化特别提醒贵方妥善保管您的用户名和密码，不要将您的账号信息透露给任何其他人，不要向他人透露、复制、转让注册账户或授权许可他人使用注册账户。当使用完博物文化服务后，应安全退出。
                            （5）博物文化仅根据服务器接收到的用户名、密码进行身份确认，在用户名、密码准确的情况下，博物文化将该登录行为视为系贵方亲自实施。用户发现任何账号异常情况，应立即通知博物文化请求采取应急措施，但该账号登录后可能导致的损失由贵方承担，博物文化不负任何责任。
                            （6）用户应遵守本协议的各项条款，正确、适当地使用本服务，否则，博物文化有权依据本协议终止对贵方文旅绿码账号提供服务。同时，根据贵方的违规情节，博物文化有权采取包括但不限于以下处罚措施：暂时、永久冻结封禁账号（、回收账号、扣罚保证金等，由此产生的损失由用户自行承担。
                            （7）如果用户超过180天未使用、或停止使用本服务或服务被终止或取消，博物文化有权回收账号且无需事先通知贵方即可从服务器上永久地删除用户的数据。服务停止、终止或取消后，博物文化没有义务向用户返还任何数据。用户同意博物文化有权行使上述权利且不需对用户或第三方承担任何责任。 
                        4.5 关于用户名和昵称使用： 
                            （1）不得使用党和国家领导人或其他知名人士的真实姓名、字号、艺名、笔名作为用户名和昵称； 
                            （2）不得使用国家机构或其他机构的名称作为用户名和昵称；
                            （3）不得使用和其他知名网友之名相同或近似的用户名和昵称； 
                            （4）不得使用不文明、不健康，或带攻击性、侮辱性的用户名和昵称； 
                            （5）请勿使用易产生歧义、引起他人误解的用户名和昵称；
                            （6）不得使用其他具有不良影响的用户名和昵称； 对于违反以上规定或产生不良后果的用户名和昵称，博物文化有权删除而不必事先通知。 
                        4.6 关于发表及评论内容规则
                            （1）遵守相关法规，严禁发表违反法律法规及社会主义道德规定的内容； 
                            （2）使用文明用语，不得张贴对任何人进行人身攻击、谩骂、诋毁的言论； 
                            （3）不得张贴未经公开报道、未经证实的消息；
                            （4）不得张贴与所在论坛主题无关的消息、言论和图片； 
                            （5）转贴文章应注明原始出处和时间； 对于违反以上规定的内容，在不需要通知和解释的情况下，博物文化有权予以删除，并根据违规情节作出处罚措施。 
                        4.7 关于贴图规则
                            （1）不得张贴宣扬反动、封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖、教唆犯罪等不符合国家法律规定的以及任何包含种族、性别、宗教歧视性和猥亵性的图片；
                            （2）不得出现带有侮辱性、挑衅、辱骂其他人以及不健康内容的图片； 请勿使用易产生歧义、引起他人误解的图片； 
                            （3）摘录、转贴的图片请注明出处及作者，禁止张贴侵犯他人著作权、版权等知识产权的图片；
                            （4）不得张贴与论坛主题无关的图片； 博物文化有权在不需要通知和解释的情况下删除违反以上规定的图片，并根据违规情节作出处罚措施。 
                        5. 知识产权 
                            5.1 文旅绿码由杭州博物文化传播有限公司独立自主开发完成，其产品、服务所涉及的著作权、商标权、专利权、商业秘密等知识产权、其他相关权利均独立归属博物文化所有。与博物文化服务相关的所有信息，包括但不限于：博物文化商标标识、网页/产品版面设计、界面设计、图标、色彩、文字表述、数据、代码、电子文档及其组合等均受中华人民共和国著作权法、商标法、专利法、反不正当竞争法和相应的国际条约以及其他知识产权法律法规的保护。
                            5.2 用户使用博物文化服务只能在本协议以及相应的授权许可范围内使用博物文化的知识产权，未经授权禁止任何超范围使用行为, 不论是否以商业为目的。 
                        6. 隐私政策
                            博物文化非常重视贵方的隐私和个人信息，建议未成年用户请监护人仔细阅读本隐私权政策。为此我们专门制定了《隐私政策》，希望向您说明我们在贵方使用博物文化服务时收集何种信息以及如何收集、使用和保护这些信息。
                        在使用博物文化服务前，请您务必仔细阅读我们的隐私政策。除非贵方已阅读并接受本协议的全部条款，否则请贵方立即停止使用博物文化服务。一旦贵方选择注册了博物文化账号或以第三方平台账号登录或实际使用博物文化服务，即视为贵方已充分理解并接受本隐私政策。
                        7. 责任限制与不可抗力 
                            7.1
                            博物文化按照现有技术和条件所能达到的现状向用户提供文旅绿码服务，并尽最大努力确保服务的安全性和稳定性。博物文化不担保所提供的网络服务一定能满足用户的要求，也不担保提供的信息存储空间容量充足或其提供的网络服务不会被中断，且对服务的及时性、安全性、出错发生，以及信息是否能准确、及时、顺利的传送均不作任何担保，也不承担任何法律责任，但博物文化将尽力减少因此给用户造成的损失和影响。
                            7.2 为了向用户提供优质服务，博物文化有权不经用户同意而变更、改变、删除、增加文旅绿码服务，也可能暂停或彻底终止本服务，而不承担任何法律责任。 
                            7.3 在使用文旅绿码服务过程中可能存在来自多方面的风险，包括威胁性、诽谤性、非法的、或侵犯他人合法权利的风险信息，用户需自行承担该风险。博物文化对用户经由文旅绿码提供的服务所获得的信息亦不做任何担保，包括真实性、合法性、非侵权性等，尤其是用户通过博物文化提供的服务获得的商业广告信息、交友信息等。用户须自行甄别信息的真实性并谨慎预防可能存在的风险，因此而产生的任何直接、间接、偶然、附带或衍生的损失或损害，博物文化不承担任何责任。
                            7.4 用户理解，博物文化服务容易受到互联网安全问题的困扰，并由于互联网技术的不稳定性，可能遭遇基础电信运营商移动通信网络的故障、技术缺陷、覆盖范围限制、不可抗力、计算机病毒、黑客攻击、服务器系统崩溃等风险，上述风险可能导致文旅绿码服务中断或数据损失，博物文化对此等不可抗力因素导致的损失不承担责任。
                        8. 通知 所有发给用户的通知都可通过电子邮件、常规的信件、短信或通过系统通知、私信、或页面公告等方式进行传送。博物文化将通过上述方法之一将消息传递给用户，告知他们服务条款的修改、服务变更等。 
                        9. 法律责任 
                            9.1 如果发现或收到他人举报或投诉用户违反本协议约定的，博物文化有权不经通知随时对相关内容进行删除，并视行为情节对违规帐号处以包括但不限于警告、限制或禁止使用全部或部分服务功能、暂时或永久冻结封禁账号、回收注销账号。
                            9.2 用户理解并同意，博物文化有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规行为采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等， 用户应独自承担由此而产生的一切法律责任。
                            9.3 用户理解并同意，因用户违反本协议或相关的服务条款的规定，导致或产生的任何第三方主张的任何损失，包括但不限于：赔偿款、诉讼费、律师费、公证费、差旅费等直接、间接损失，用户应当全额赔偿博物文化及该第三方，并使之免受损害。
                        10. 法律适用及管辖 本协议适用中华人民共和国法律（香港、澳门及台湾地区除外），所产生之纠纷应提交杭州博物文化传播有限公司住所地人民法院管辖。
                        


    </pre>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { Toast } from "vant";
import { Dialog } from "vant";
import { Card } from "../api/api";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      show: false,
      columns: ["身份证", "社会保障卡", "护照", "军官证"],
      phone: "", //手机号
      name: "", //姓名
      cardNum: "", //证件号
      checked: true, //选中框
      read: false, //协议弹出框
      certificateType:'身份证',//证件类型
      museumInformtaion:''//博物馆id
    };
  },
  created(){
    if(this.$route.query.index !== undefined) {
    let { phone, name, cardNum, certificateType, isSelected } = this.getPer(this.$route.query.index);
    this.phone = phone;
    this.name = name;
    this.cardNum = cardNum;
    this.certificateType = certificateType;
    this.isSelected = isSelected;
    }
  },
  computed : {
    ...mapGetters(['getPer'])
  },
  methods: {
    //弹出身份类型选择
    showPopup() {
      this.show = !this.show;
    },
    // 身份类型确认
    onConfirm(value, index) {
      this.show = !this.show;
      this.certificateType = value;
      console.log(this.certificateType)
    },
    // 身份类型改变时输出
    onChange(picker, value, index) {
      console.log(`当前值：${value}, 当前索引：${index}`);
    },
    // 身份类型选中后点击取消
    onCancel() {
      console.log("取消");
      this.show = !this.show;
    },

    //  点击协议框弹出
    xieyi() {
      this.read = true;
      console.log(this.read);
    },

    // 点击保存按钮
    submit() {
      //如果输入的名字为空
      if (this.name == "") {
        Toast("请输入您的名字");
        return false;
      }

      //如果手机号为空
      if (this.phone == "") {
        Toast("请输入您的手机号");
        return false;
      } else {
        if (
          !(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
        ).test(this.phone)) {
          Toast("手机号有误");
          return false;
        }
      }

      // 如果证件号为空
      if (this.cardNum == "") {
        Toast("请输入您的证件号");
        return false;
      } else {
        Card({ idCard: this.cardNum }).then((res) => {
          console.log(res.data);
           if (res.data.data == false) {
            Toast("证件号不合法");
            return false;
          }
        });
       
      }

      //是否点击阅读并同意
      if(this.checked == false){
        Toast('请阅读并点击同意协议')
        return false
      }

    let perSon = {
     cardNum : this.cardNum,
     phone : this.phone,
     name : this.name,
     certificateType : this.certificateType
   }
   
   if(this.$route.query.index !== undefined){
     perSon.isSelected = this.isSelected;
     this.$store.commit('ediot',{index: this.$route.query.index, data: perSon})
   } else {
     perSon.isSelected = true;
     this.$store.commit('ADDPER',perSon)
   }
  this.$router.push({path:'/yuyueIndex',query:{museumId:this.$route.query.museumId}})
     

    },
  },
};
</script>

<style lang="less" scoped>
html {
  font-size: 40px !important;
}
.container {
  overflow: auto;
  padding: 0 30px;
  box-sizing: border-box;
}

.information {
  width: 100%;
  padding: 10px 0;
  background-color: white;

  border-radius: 10px;
  margin-top: 100px;

  .sessionTime_text {
    float: left;
    font-size: 26px;
  }
  .sessionTime_text2 {
    float: right;
    color: #888888;
    font-size: 24px;
  }
}
.agree {
  margin-top: 30px;

  display: flex;
  .xieyi {
    font-size: 24px;
    color: #379afe;
    margin-left: 10px;
    margin-top: 5px;
  }
}
.yuyueButton {
  width: 100%;
  border-radius: 50px;
  padding: 30px 0;
  text-align: center;
  margin-top: 30px;
  background-color: #8dc6d6;
  color: white;
}
.protocol {
  font-size: 5.12px !important;
  white-space: pre-line !important;
  text-align: left !important;
  margin: 0;
  padding: 0 30px;
  box-sizing: border-box;
  color: #888;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
</style>
